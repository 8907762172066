import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { AppContext } from '../../context/AppState'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import { Typography, Box } from '@mui/material'
//import Card from '../Card/Card'

const ResponsibleConsumption = () => {
  const { state } = useContext(AppContext)
  const { product } = state
  const { responsibleConsumption } = product

  const { t } = useTranslation('responsibleConsumption')
  const isSmall = useMediaQuery('(max-width: 600px)')
  return (
    <>
      {responsibleConsumption && responsibleConsumption.isSelected && (
        <div
          style={Object.assign({}, presentationStyle.lotContainer, {
            alignItems: 'start'
          })}
        >
          <Typography
            variant='h5'
            sx={{ mb: 2, fontSize: '20px', fontWeight: 'bold' }}
          >{`${t('title', 'Responsible Consumption')}`}</Typography>

          <GridContainer
            style={{ width: '100%' }}
            direction='row'
            justify='center'
            alignItems='center'
            spacing={6}
          >
            <GridItem
              xs={12}
              style={isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {}}
            >
              {responsibleConsumption.fileUrls?.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt='responsible consumption'
                  style={{
                    width: '50px',
                    height: 'auto',
                    borderRadius: '10px',
                    //marginBottom: '10px',
                    marginRight: '10px'
                  }}
                />
              ))}
              <br />
              <Box
                sx={{
                  marginTop: '10px',
                  textAlign: isSmall ? 'center' : 'left'
                }}
              >
                <Typography variant='body'>
                  {`${t(
                    'alcoholAbuse',
                    'Alcohol abuse is dangerous for health. '
                  )}`}
                  {isSmall && <br />}
                  {`${t('drinkResponsibly', 'Drink responsibly')}`}
                </Typography>
              </Box>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </>
  )
}
export default ResponsibleConsumption
