import React from 'react'
import { Trans } from 'react-i18next'

const REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export function uuidValidate(uuid) {
  return typeof uuid === 'string' && REGEX.test(uuid)
}

export const hideOrShowProperty = (discover, property, type) => {
  if (discover && Object.keys(discover).length > 0) {
    if (!discover.metadata.visibility[type][property]) {
      if (
        discover.metadata.visibility[type][property] === undefined ||
        discover.metadata.visibility[type][property] === null
      ) {
        return true
      }
    }
    return !!discover.metadata.visibility[type][property]
  } else {
    return true
  }
}

function formatIngredient(value, index, options) {
  const { t, type, mapIngredientCode, allergensSet, sulphitesSet, showCode } =
    options
  const handleTranslation = (value) => (t ? t(`${type}.${value}`) : value)
  const handleTranslationSuphites = (value) => {
    return <Trans i18nKey={value} components={[<strong key='strong' />]} />
  }

  const isSulphite = sulphitesSet.has(value)
  const isAllergen = allergensSet.has(value)
  const code = mapIngredientCode[value] || ''
  const translation = handleTranslation(value)

  console.log('Translation : ', translation)
  let result = translation
  const renderSuphites = (value, code) => {
    return code ? (
      <span>
        {code} ({handleTranslationSuphites(value)})
      </span>
    ) : (
      handleTranslationSuphites(value)
    )
  }
  if (showCode) {
    result = code ? code : translation
    if (isAllergen) {
      result = code ? `${code} (${translation})` : translation
    } else if (isSulphite) {
      return renderSuphites(value, code)
    }
  }

  if (isAllergen) {
    return <b key={index}>{result}</b>
  }
  if (isSulphite) {
    return handleTranslationSuphites(value)
  }

  return result
}
//Function to format ingredients from acidityRegulators and agentsStabilising categories
function formatFixedAlternativeIngredients(ingredientsTemp, product, options) {
  const { t } = options
  const fixedAlternativeIngredientsObj =
    product.ingredients?.metadata?.fixedAlternative || {}

  // Separate fixed and alternative ingredients
  const fixedIngredients = []
  const alternativeIngredients = []

  ingredientsTemp.forEach((value, index) => {
    const formattedValue = formatIngredient(value, index, options)

    if (fixedAlternativeIngredientsObj[value] === 'fixed') {
      fixedIngredients.push(formattedValue)
    } else {
      alternativeIngredients.push(formattedValue)
    }
  })

  // Format ingredients
  const formattedFixedIngredients = fixedIngredients.join(', ')
  const formattedAlternativeIngredients = alternativeIngredients.join(
    ` ${t('andOr')} `
  )

  // Combine ingredients
  let combinedArray = []
  if (formattedFixedIngredients && !formattedAlternativeIngredients) {
    combinedArray = [formattedFixedIngredients]
  } else if (!formattedFixedIngredients && formattedAlternativeIngredients) {
    combinedArray = [` ${t('contain')} `, formattedAlternativeIngredients]
  } else {
    combinedArray = [
      formattedFixedIngredients,
      `, ${t('contain')} `,
      formattedAlternativeIngredients
    ]
  }

  return combinedArray
}
export function getIngredientsTemps(
  category,
  arrayIngredientsCategory,
  arrayToFilter,
  mapIngredientCode,
  product,
  type,
  t
) {
  const sulphitesSet = new Set([
    'potassiumBisulphite',
    'potassiumMetabisulphite',
    'potassiumBisulphite2',
    'potassiumMetabisulphite2',
    'ammoniumBisulphite',
    'sulphites1',
    'sulphites',
    'sulphites2',
    'sulphiteCausticCaramel',
    'sulphiteAmmoniaCaramel',
    'potassiumBisulphite1',
    'potassiumMetabisulphite1',
    'sulphites*',
    'sulphites1*',
    'sulphites2*'
  ])
  const allergensSet = new Set([
    'lysozyme',
    'lysozyme1',
    //'potassiumBisulphite',
    'sulphurDioxide1',
    'sulphurDioxide2',
    //'potassiumMetabisulphite',
    //'potassiumMetabisulphite1',
    //'potassiumBisulphite1',
    //'sulphites',
    //'sulphites1',
    'lysozyme2',
    //'potassiumBisulphite2',
    'sulphurDioxide3',
    //'potassiumMetabisulphite2',
    //'sulphites2',
    'casein',
    'potassiumCaseinates'
  ])

  const andOrCategories = new Set([
    'acidityRegulators',
    'agentsStabilising'
    // 'aromatic_acidityRegulators',
    // 'aromatic_agentsStabilising'
  ])

  let ingredientsTemp = arrayToFilter.filter((el) =>
    arrayIngredientsCategory.includes(el)
  )
  if (!ingredientsTemp.length) {
    return []
  }

  const showCodeObj = product.ingredients?.metadata?.showCode || {}
  const showCode = showCodeObj[category] || false

  if (andOrCategories.has(category)) {
    return formatFixedAlternativeIngredients(ingredientsTemp, product, {
      t,
      type,
      mapIngredientCode,
      allergensSet,
      sulphitesSet,
      showCode
    })
  }

  ingredientsTemp = ingredientsTemp.map((value, index) =>
    formatIngredient(value, index, {
      t,
      type,
      mapIngredientCode,
      allergensSet,
      sulphitesSet,
      showCode
    })
  )

  const separator = andOrCategories.has(category) ? ` ${t('andOr')} ` : ', '

  ingredientsTemp = ingredientsTemp.reduce((prev, curr, index) => {
    return index !== ingredientsTemp.length - 1
      ? [...prev, curr, separator]
      : [...prev, curr]
  }, [])

  return ingredientsTemp
}

export function isValidNumberLatLng(input) {
  // Verifica se la stringa è valida tramite un'espressione regolare
  const regex = /^[-+]?([0-9]+([.,]?[0-9]*)?)$/
  return regex.test(input)
}
export function isValidNumberLat(lat) {
  const regex = /^[-+]?([0-9]+([.,]?[0-9]*)?)$/
  if (!regex.test(lat)) {
    return false
  }
  const value = parseFloat(lat.replace(',', '.'))
  return value >= -90 && value <= 90
}

export function isValidNumberLng(lng) {
  const regex = /^[-+]?([0-9]+([.,]?[0-9]*)?)$/
  if (!regex.test(lng)) {
    return false
  }
  const value = parseFloat(lng.replace(',', '.'))
  return value >= -180 && value <= 180
}

export function areBothNonZero(lat, lng) {
  const latValue = parseFloat(lat.replace(',', '.'))
  const lngValue = parseFloat(lng.replace(',', '.'))
  return latValue !== 0 || lngValue !== 0
}
