import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppState'
import NutritionalValue from '../AttributesGrid/NutritionalValue'
import ResponsibleConsumption from '../AttributesGrid/ResponsibleConsumption'
import IngredientsSection from '../AttributesGrid/IngredientsSection'
import IngredientsAromaticWineSection from '../AttributesGrid/IngredientsAromaticWineSection'
// import AllergenesSection from '../AttributesGrid/AllergenesSection'
//import Button from '../CustomButtons/Button.js'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ProductTab = () => {
  const { t } = useTranslation('productTab')
  const { state } = useContext(AppContext)
  const { sectionActive, product } = state
  // const { mobile } = props
  useEffect(() => {
    // window.scrollTo(0, 0)
  }, [sectionActive])
  const isSmall = useMediaQuery('(max-width: 600px)')

  return (
    <>
      {product?.ingredients?.metadata?.isAromaticWine ? (
        <IngredientsAromaticWineSection />
      ) : (
        <IngredientsSection />
      )}

      {product?.ingredients?.values?.some((value) => value.includes('*')) && (
        <div
          style={Object.assign(
            {},
            presentationStyle.ingredientContainer,
            isSmall ? {} : { background: 'white' }
          )}
        >
          <Typography variant='body' sx={{ fontSize: '14px' }}>
            {t('bioAgri')}
          </Typography>
        </div>
      )}
      {/* <AllergenesSection /> */}
      <NutritionalValue />
      {/* <Button justIcon simple>
        <i
          className='fa-brands fa-x-twitter'
          style={{ fontSize: '18px', color: '#1DA1F2' }}
        ></i>
      </Button> */}
      <ResponsibleConsumption />
      {/* {Object.keys(product.nutritionalValue).length > 0 && <NutritionalValue />} */}
      {/* {Object.keys(product.ingredients).length > 0 && <IngredientsSection />}
      {Object.keys(product.allergenes).length > 0 && <AllergenesSection />} */}
    </>
  )
}
export default ProductTab
