import React, { useContext } from 'react'
import { AppContext } from '../../context/AppState'
import useMediaQuery from '@mui/material/useMediaQuery'

import Button from '../CustomButtons/Button.js'
import { Box } from '@mui/material'

const instagramUrl = 'https://www.instagram.com/'
const instagramApp = 'instagram://user?'

const SocialLinks = () => {
  const isSmall = useMediaQuery('(max-width: 600px)')

  const { state } = useContext(AppContext)
  const { company } = state
  // const { social, website } = company
  const { social } = company
  let instagramDesktop
  let instagramMobile
  if (social && social.instagram) {
    instagramDesktop = `${instagramUrl}${social.instagram.split('@')[1]}`
    instagramMobile = `${instagramApp}username=${
      social.instagram.split('@')[1]
    }`
  } else {
    instagramDesktop = null
    instagramMobile = null
  }
  return (
    <Box
      component='div'
      // display={isSmall && (sectionActive !== 1 && sectionActive !== 2) ? 'none' : 'block'}
      style={isSmall ? { marginBottom: '30px' } : { marginBottom: 0 }}
    >
      {/* {website && (
        <Button
          disabled={!website}
          href={website || ''}
          target='_blank'
          color='github'
          justIcon
          simple
        >
          <i
            className='fas fa-globe'
            style={{ fontSize: '18px', color: '#4267B2' }}
          />
        </Button>
      )} */}
      {social && social.facebook && (
        <Button
          disabled={!(social && social.facebook)}
          href={
            social && social.facebook && social.facebook.startsWith('http')
              ? social.facebook ?? ''
              : `https://${social.facebook}`
          }
          target='_blank'
          color='facebook'
          justIcon
          simple
        >
          <i
            className='fab fa-facebook-f'
            style={{ fontSize: '18px', color: '#4267B2' }}
          />
        </Button>
      )}
      {social && social.linkedin && (
        <Button
          disabled={!(social && social.linkedin)}
          href={
            social && social.linkedin && social.linkedin.startsWith('http')
              ? social.linkedin ?? ''
              : `https://${social.linkedin}`
          }
          target='_blank'
          color='linkedin'
          justIcon
          simple
        >
          <i
            className='fab fa-linkedin-in'
            style={{ fontSize: '18px', color: '#C13584' }}
          />
        </Button>
      )}
      {social && social.twitter && (
        <Button
          disabled={!(social && social.twitter)}
          href={
            social && social.twitter && social.twitter.startsWith('http')
              ? social.twitter ?? ''
              : `https://${social.twitter}`
          }
          target='_blank'
          color='twitter'
          justIcon
          simple
        >
          <i
            className='fab fa-twitter'
            style={{ fontSize: '18px', color: '#1DA1F2' }}
          />
        </Button>
      )}

      {social && social.instagram ? (
        !isSmall ? (
          <Button
            disabled={!(social && social.instagram)}
            href={(social && social.instagram && `${instagramDesktop}`) || ''}
            target='_blank'
            color='instagram'
            justIcon
            simple
          >
            <i
              className='fab fa-instagram'
              style={{ fontSize: '18px', color: '#C13584' }}
            />
          </Button>
        ) : (
          <Button
            disabled={!(social && social.instagram)}
            href={(social && social.instagram && `${instagramMobile}`) || ''}
            target='_blank'
            color='instagram'
            justIcon
            simple
          >
            <i
              className='fab fa-instagram'
              style={{ fontSize: '18px', color: '#C13584' }}
            />
          </Button>
        )
      ) : null}
    </Box>
  )
}

export default SocialLinks
