import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { AppContext } from '../../context/AppState'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import { Typography /* Box */ } from '@mui/material'
//import Card from '../Card/Card'

const NutritionalValue = () => {
  const { state } = useContext(AppContext)
  const { product } = state
  const [valueObj, setValueObj] = useState({})
  const [nutritionalValue, setNutritionalValue] = useState(false)

  const { t } = useTranslation('nutrition')
  const isSmall = useMediaQuery('(max-width: 600px)')
  let obj = {}

  useEffect(() => {
    /* for (const el of product.attributes) {
      if (el.name.includes('<VN>')) {
        values.push(el)
      }
    } */
    if (!product.nutritionalValue) {
      obj = {
        energy: {
          standardGrams: '0'
        },
        fat: {
          standardGrams: '0'
        },
        ofFatty: {
          standardGrams: '0'
        },
        carbo: {
          standardGrams: '0'
        },
        sugar: {
          standardGrams: '0'
        },
        proteins: {
          standardGrams: '0'
        },
        salt: {
          standardGrams: '0'
        }
      }
    } else {
      obj = product.nutritionalValue
    }
    const kj = obj.energy && obj.energy.kJ && obj.energy.kJ.standardGrams
    const kcal = obj.energy && obj.energy.kcal && obj.energy.kcal.standardGrams
    setValueObj({
      ...obj,
      energy: {
        standardGrams:
          kj || kcal ? `${kj || '0'} kJ / ${kcal || '0'} kcal` : null
      }
    })

    // eslint-disable-next-line
  }, [product.metadata])

  useEffect(() => {
    const nutritionalValue = product.nutritionalValue
    let found = false
    // Itera su ogni chiave di nutritionalValue
    if (!nutritionalValue) {
      return
    }
    for (const key in nutritionalValue) {
      const nutrient = nutritionalValue[key]
      // Ignora la chiave "label"
      if (key === 'label') {
        continue
      }
      // Controllo a se per la chiave "energy" poichè non ha direttamente la proprietà standardGrams
      if (key === 'energy') {
        if (
          'kJ' in nutrient &&
          nutrient?.kJ?.standardGrams &&
          nutrient?.kJ?.standardGrams !== ''
        ) {
          setNutritionalValue(true)
          found = true
          break
        }
        if (
          'kcal' in nutrient &&
          nutrient?.kcal?.standardGrams &&
          nutrient?.kcal?.standardGrams !== ''
        ) {
          setNutritionalValue(true)
          found = true
          break
        }
      } else {
        // Controlla se la proprietà standardGrams è popolata
        if (nutrient?.standardGrams && nutrient?.standardGrams !== '') {
          setNutritionalValue(true)
          found = true
          break
        }
      }
    }

    if (!found) {
      setNutritionalValue(false)
    }
  }, [])

  const getUnitDose = (unitDose, value) => {
    const numericValue = parseFloat(value)
    if (isNaN(numericValue) || numericValue < 0) {
      return '-'
    }
    const toReturn = (unitDose * numericValue) / 100
    return toReturn
  }
  const getUnitDoseEnergy = (unitDose, value) => {
    const kJMatch = value.match(/(\d+(\.\d+)?)\s*kJ/) // Trova il valore in kJ
    const kcalMatch = value.match(/(\d+(\.\d+)?)\s*kcal/) // Trova il valore in kcal
    if (!kJMatch || !kcalMatch) {
      return '-'
    }
    const kJValue = parseFloat(kJMatch[1])
    const kcalValue = parseFloat(kcalMatch[1])
    if (isNaN(kJValue) || isNaN(kcalValue) || kJValue < 0 || kcalValue < 0) {
      return '-'
    }
    const kJPerUnitDose = (unitDose * kJValue) / 100
    const kcalPerUnitDose = (unitDose * kcalValue) / 100
    const resultString = `${kJPerUnitDose.toFixed(
      0
    )} kJ / ${kcalPerUnitDose.toFixed(0)} kcal`
    return resultString
  }

  return (
    <>
      {nutritionalValue && (
        <div
          style={Object.assign({}, presentationStyle.lotContainer, {
            alignItems: 'start'
          })}
        >
          <Typography
            variant='h5'
            sx={{ mb: 2, fontSize: '20px', fontWeight: 'bold' }}
          >{`${t('title')}`}</Typography>

          <GridContainer
            style={{ width: '100%' }}
            direction='row'
            justify='center'
            alignItems='center'
            spacing={6}
          >
            <GridItem
              xs={12}
              style={isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {}}
            >
              {nutritionalValue && (
                <div className='nutritionTable'>
                  {/* <div style={{ display: 'flex' }} className='nutritionTable'> */}
                  <GridContainer className='singleTable'>
                    <GridItem xs={12}>
                      <GridContainer spacing={1}>
                        <GridItem xs={valueObj.unitDose?.show ? 6 : 7}>
                          <Typography
                            variant='p'
                            sx={{ fontWeight: 'bold' }}
                          >{`${t('titleTable')}`}</Typography>
                        </GridItem>
                        <GridItem
                          xs={valueObj.unitDose?.show ? 3 : 5}
                          sx={{ textAlign: 'end' }}
                        >
                          <Typography variant='p' sx={{ fontWeight: 'bold' }}>
                            {valueObj.label?.standardGrams
                              ? `100 ${valueObj.label.standardGrams}`
                              : '100 ml'}
                          </Typography>
                        </GridItem>
                        {valueObj.unitDose?.show && (
                          <GridItem xs={3} sx={{ textAlign: 'end' }}>
                            <Typography variant='p' sx={{ fontWeight: 'bold' }}>
                              {valueObj.unitDose?.show
                                ? valueObj.label?.standardGrams
                                  ? `${t('portion')} (${
                                      valueObj.unitDose?.standardGrams ?? '-'
                                    } ${valueObj.label.standardGrams})`
                                  : `${t('portion')} (${
                                      valueObj.unitDose?.standardGrams ?? '-'
                                    } ml)`
                                : '-'}
                            </Typography>
                          </GridItem>
                        )}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer spacing={1}>
                        <GridItem xs={valueObj.unitDose?.show ? 6 : 7}>
                          <Typography variant='p'>{`${t(
                            'energy'
                          )}`}</Typography>
                        </GridItem>
                        <GridItem
                          xs={valueObj.unitDose?.show ? 3 : 5}
                          sx={{ textAlign: 'end' }}
                        >
                          <Typography variant='p'>
                            {valueObj.energy && valueObj.energy.standardGrams
                              ? valueObj.energy.standardGrams
                              : '0 kJ / 0 kcal'}
                          </Typography>
                        </GridItem>
                        {valueObj.unitDose?.show && (
                          <GridItem xs={3} sx={{ textAlign: 'end' }}>
                            <Typography variant='p'>
                              {valueObj.energy &&
                              valueObj.energy.standardGrams &&
                              valueObj.unitDose?.standardGrams
                                ? `${getUnitDoseEnergy(
                                    valueObj.unitDose.standardGrams,
                                    valueObj.energy.standardGrams
                                  )}`
                                : '0 kJ / 0 kcal'}
                            </Typography>
                          </GridItem>
                        )}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer spacing={1}>
                        <GridItem xs={valueObj.unitDose?.show ? 6 : 7}>
                          <Typography variant='p'>{`${t('fat')}`}</Typography>
                        </GridItem>
                        <GridItem
                          xs={valueObj.unitDose?.show ? 3 : 5}
                          sx={{ textAlign: 'end' }}
                        >
                          <Typography
                            className='textWithUomNutritionalValue'
                            variant='p'
                          >
                            {valueObj.fat?.standardGrams
                              ? `${valueObj.fat.standardGrams} g`
                              : '0 g'}
                          </Typography>
                        </GridItem>
                        {valueObj.unitDose?.show && (
                          <GridItem xs={3} sx={{ textAlign: 'end' }}>
                            <Typography
                              className='textWithUomNutritionalValue'
                              variant='p'
                            >
                              {valueObj.fat?.standardGrams &&
                              valueObj.unitDose?.standardGrams
                                ? `${getUnitDose(
                                    valueObj.unitDose.standardGrams,
                                    valueObj.fat.standardGrams
                                  )} g`
                                : '0 g'}
                            </Typography>
                          </GridItem>
                        )}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer spacing={1}>
                        <GridItem xs={7}>{t('contains')}</GridItem>
                        <GridItem xs={valueObj.unitDose?.show ? 6 : 7}>
                          {t('fatSub')}
                        </GridItem>
                        <GridItem
                          xs={valueObj.unitDose?.show ? 3 : 5}
                          sx={{ textAlign: 'end' }}
                        >
                          <Typography
                            className='textWithUomNutritionalValue'
                            variant='p'
                          >
                            {valueObj.ofFatty?.standardGrams
                              ? `${valueObj.ofFatty.standardGrams} g`
                              : '0 g'}
                          </Typography>
                        </GridItem>
                        {valueObj.unitDose?.show && (
                          <GridItem xs={3} sx={{ textAlign: 'end' }}>
                            <Typography
                              className='textWithUomNutritionalValue'
                              variant='p'
                            >
                              {valueObj.ofFatty?.standardGrams &&
                              valueObj.unitDose?.standardGrams
                                ? `${getUnitDose(
                                    valueObj.unitDose.standardGrams,
                                    valueObj.ofFatty.standardGrams
                                  )} g`
                                : '0 g'}
                            </Typography>
                          </GridItem>
                        )}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer spacing={1}>
                        <GridItem xs={valueObj.unitDose?.show ? 6 : 7}>
                          <Typography variant='p'>{`${t('carbs')}`}</Typography>
                        </GridItem>
                        <GridItem
                          xs={valueObj.unitDose?.show ? 3 : 5}
                          sx={{ textAlign: 'end' }}
                        >
                          <Typography
                            className='textWithUomNutritionalValue'
                            variant='p'
                          >
                            {valueObj.carbo?.standardGrams
                              ? `${valueObj.carbo.standardGrams} g`
                              : '0 g'}
                          </Typography>
                        </GridItem>
                        {valueObj.unitDose?.show && (
                          <GridItem xs={3} sx={{ textAlign: 'end' }}>
                            <Typography
                              className='textWithUomNutritionalValue'
                              variant='p'
                            >
                              {valueObj.carbo?.standardGrams &&
                              valueObj.unitDose?.standardGrams
                                ? `${getUnitDose(
                                    valueObj.unitDose.standardGrams,
                                    valueObj.carbo.standardGrams
                                  )} g`
                                : '0 g'}
                            </Typography>
                          </GridItem>
                        )}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer spacing={1}>
                        <GridItem xs={7}>{t('contains')}</GridItem>
                        <GridItem xs={valueObj.unitDose?.show ? 6 : 7}>
                          {t('carbsSub')}
                        </GridItem>
                        <GridItem
                          xs={valueObj.unitDose?.show ? 3 : 5}
                          sx={{ textAlign: 'end' }}
                        >
                          <Typography
                            className='textWithUomNutritionalValue'
                            variant='p'
                          >
                            {valueObj.sugar?.standardGrams
                              ? `${valueObj.sugar.standardGrams} g`
                              : '0 g'}
                          </Typography>
                        </GridItem>
                        {valueObj.unitDose?.show && (
                          <GridItem xs={3} sx={{ textAlign: 'end' }}>
                            <Typography
                              className='textWithUomNutritionalValue'
                              variant='p'
                            >
                              {valueObj.sugar?.standardGrams &&
                              valueObj.unitDose?.standardGrams
                                ? `${getUnitDose(
                                    valueObj.unitDose.standardGrams,
                                    valueObj.sugar.standardGrams
                                  )} g`
                                : '0 g'}
                            </Typography>
                          </GridItem>
                        )}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer spacing={1}>
                        <GridItem xs={valueObj.unitDose?.show ? 6 : 7}>
                          <Typography variant='p'>{`${t(
                            'proteins'
                          )}`}</Typography>
                        </GridItem>
                        <GridItem
                          xs={valueObj.unitDose?.show ? 3 : 5}
                          sx={{ textAlign: 'end' }}
                        >
                          <Typography
                            className='textWithUomNutritionalValue'
                            variant='p'
                          >
                            {valueObj.proteins?.standardGrams
                              ? `${valueObj.proteins.standardGrams} g`
                              : '0 g'}
                          </Typography>
                        </GridItem>
                        {valueObj.unitDose?.show && (
                          <GridItem xs={3} sx={{ textAlign: 'end' }}>
                            <Typography
                              className='textWithUomNutritionalValue'
                              variant='p'
                            >
                              {valueObj.proteins?.standardGrams &&
                              valueObj.unitDose?.standardGrams
                                ? `${getUnitDose(
                                    valueObj.unitDose.standardGrams,
                                    valueObj.proteins.standardGrams
                                  )} g`
                                : '0 g'}
                            </Typography>
                          </GridItem>
                        )}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer spacing={1}>
                        <GridItem xs={valueObj.unitDose?.show ? 6 : 7}>
                          <Typography variant='p'>{`${t('salt')}`}</Typography>
                        </GridItem>
                        <GridItem
                          xs={valueObj.unitDose?.show ? 3 : 5}
                          sx={{ textAlign: 'end' }}
                        >
                          <Typography
                            className='textWithUomNutritionalValue'
                            variant='p'
                          >
                            {valueObj.salt?.standardGrams
                              ? `${valueObj.salt.standardGrams} g`
                              : '0 g'}
                          </Typography>
                        </GridItem>
                        {valueObj.unitDose?.show && (
                          <GridItem xs={3} sx={{ textAlign: 'end' }}>
                            <Typography
                              className='textWithUomNutritionalValue'
                              variant='p'
                            >
                              {valueObj.salt?.standardGrams &&
                              valueObj.unitDose?.standardGrams
                                ? `${getUnitDose(
                                    valueObj.unitDose.standardGrams,
                                    valueObj.salt.standardGrams
                                  )} g`
                                : '0 g'}
                            </Typography>
                          </GridItem>
                        )}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  {/* <GridContainer className='singleTable'>
              <GridItem key={String(0)} xs={12} >
                <Typography variant='h6'>{valuesArray["energy"]?valuesArray.energy.standardGrams: 'No value'}</Typography>
              </GridItem>
              <GridItem key={String(1)} xs={12}>
                <Typography variant='h6'>{valuesArray["fat"]?valuesArray.fat.standardGrams: 'No value'}</Typography>
              </GridItem>
              <GridItem key={String(2)} xs={12}>
                <Typography variant='h6'>{valuesArray["ofFatty"]?valuesArray.ofFatty.standardGrams: 'No value'}</Typography>
              </GridItem>
              <GridItem key={String(3)} xs={12}>
                <Typography variant='h6'>{valuesArray["carbo"]?valuesArray.carbo.standardGrams: 'No value'}</Typography>
              </GridItem>
              <GridItem key={String(4)} xs={12}>
                <Typography variant='h6'>{valuesArray["sugar"]?valuesArray.sugar.standardGrams: 'No value'}</Typography>
              </GridItem>
              <GridItem key={String(5)} xs={12}>
                <Typography variant='h6'>{valuesArray["proteins"]?valuesArray.proteins.standardGrams: 'No value'}</Typography>
              </GridItem>
              <GridItem key={String(6)} xs={12}>
                <Typography variant='h6'>{valuesArray["salt"]?valuesArray.salt.standardGrams: 'No value'}</Typography>
              </GridItem>
            </GridContainer> */}
                </div>
              )}
              {/*  : (
            <Card style={presentationStyle.cardContainer}>
              <Box pt={4} pb={4}>
                {product.allergenes && product.allergenes.length === 0 && (
                  <Typography
                    variant='body2'
                    component='div'
                    style={presentationStyle.cardDescription}
                  >
                    Non ci sono allergeni da mostrare
                  </Typography>
                )}
              </Box>
            </Card>
          )}  */}
            </GridItem>
          </GridContainer>
        </div>
      )}
    </>
  )
}
export default NutritionalValue
