import React, { useEffect, useContext, useRef } from 'react'
import './style.css'
// nodejs library that concatenates classes
// @mui/material components
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
// import companyToTemplate from '../../companyToTemplate'
import { AppContext } from '../../context/AppState'
// import { useTranslation } from 'react-i18next'
// import { domainToLogos } from '../../domainToLogos'
// core components
// import Dropdown from '../../components/CustomDropdown/CustomDropdown.js'

import { isLocalhost, domain } from '../../serviceWorker'
import Parallax from '../../components/Parallax/Parallax.js'
// import RegularButton from '../../components/CustomButtons/Button.js'
import Card from '../../components/Card/Card.js'
// import CardHeader from '../../components/Card/CardHeader.js'
// import CardBody from '../../components/Card/CardBody.js'
// import Menu from '../../components/Menu/Menu'
import NotFound from '../../components/NotFound/NotFound'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import CompanyInfo from '../../components/CompanyInfo/CompanyInfo.js'
import CompanyInfoMobile from '../../components/CompanyInfo/CompanyInfoMobile.js'

//import CertificationsTable from '../../components/CertificationsTable/CertificationsTable.js'
import Disposal from '../../components/Disposal/Disposal.js'
// import ProductPage from '../../components/ProductPages/ProductPage'
//import CharacteristicsPage from '../../components/CharacteristicsPage/CharacteristicsPage'
import CertDialog from '../../components/CertDialog/CertDialog.js'
import DescriptionDialog from '../../components/DescriptionDialog/DescriptionDialog.js'
import DocumentsDialog from '../../components/DocumentsDialog/DocumentsDialog.js'
// import SocialLinks from '../../components/SocialLinks/SocialLinks.js'
// import LanguageIcon from '@mui/icons-material/Language'

// import BuyNowModal from '../../components/BuyNowModal/BuyNowModal.js'

import {
  getProduct,
  // getProject,
  getDiscovers,
  getLots,
  getLightLots,
  getCompany,
  populateLotData,
  populateProductData,
  getFacilities
} from '../../actions/AppActions'
// import { changeLanguage } from '../../utils/translations'
// import CompanyVerificationBanner from '../../components/CompanyVerificationBanner'

import PresentationPageMobile from './PresentationPageMobile'
import PresentationPageDesktop from './PresentationPageDesktop'
import LogoLoader from '../../components/LogoLoaderImage/index'
import { alog } from '../../utils/apioLog'
import { hideOrShowProperty } from '../../utils/utils'
import Footer from '../../components/Footer/Footer'
// const useStyles = makeStyles(presentationStyle)
// import NutritionalValue from '../../components/AttributesGrid/NutritionalValue'
import ProductTab from '../../components/ProductTab/ProductTab'
// import 'react-flags-select/scss/react-flags-select.scss'
import SelectLanguage from 'components/SelectLanguage/SelectLanguage'
import CompanyLogo from 'components/CompanyLogo/CompanyLogo'

// main hook
export default function PresentationPage() {
  // const { t } = useTranslation('presentationPage')
  // const [openBuyNow, setOpenBuyNow] = React.useState(false)

  // React-router hook per prendere i parametri passati nell'url (uuid o gtin prodotto, uuid o lotNumber del lotto)
  const { prod, lot, version } = useParams()
  // stato globale dell'app e funzione che fa il dispatch delle azioni
  const { state, dispatch } = useContext(AppContext)

  const {
    sectionActive,
    clickedByMenu,
    product,
    informationFound,
    informationLoaded,
    company,
    discover
  } = state

  alog('isLocalHost', isLocalhost, 'report')
  // const domainSplitted = domain.split('.')

  alog('domain', domain.domain, 'domain', 'ok')
  // funzione che controlla il link al social e se manca l'http lo aggiunge
  const checkHttpInSocialLinks = (socialLinks) => {
    Object.keys(socialLinks).forEach((social) => {
      if (
        socialLinks[social] &&
        !socialLinks[social].startsWith('http://') &&
        !socialLinks[social].startsWith('https://')
      ) {
        socialLinks[social] = `http://${socialLinks[social]}`
      }
    })
    return socialLinks
  }

  // funzione che scarica i dati del prodotto scansionato e della sua azienda produttrice
  async function fetchProducts() {
    try {
      let product
      // NOTE:this is useful in case of a product with a version im a gtin
      if (version) {
        product = await getProduct(
          prod.concat(`${encodeURIComponent(`/22/${version}`)}`)
        )
      } else {
        product = await getProduct(prod)
      }
      // const project = await getProject(product.projectId)
      const company = await getCompany(product.companyId)
      const facilities = await getFacilities(company.projectId)
      // product = await populateProductData(product)

      product = await populateProductData(product)

      if (product && product.social) {
        const { website, ...social } = checkHttpInSocialLinks({
          ...product.social,
          website: product.website
        })
        product.social = social
        product.website = website
      }

      dispatch({
        type: 'SET_PRODUCT',
        payload: product
      })
      dispatch({
        type: 'SET_COMPANY',
        payload: company
      })
      dispatch({
        type: 'SET_FACILITIES',
        payload: facilities
      })
      dispatch({
        type: 'SET_INFORMATIONLOADED',
        payload: true
      })
    } catch (e) {
      dispatch({
        type: 'SET_INFO_FOUND',
        payload: false
      })
    }
  }

  async function fetchDiscoverResource(params) {
    try {
      const discover = await getDiscovers(params)

      dispatch({
        type: 'SET_DISCOVER',
        payload: discover
      })
    } catch (e) {
      alog('No hiding', 'DEBUG', 'info')
    }
  }

  // funzione che fa il fetch di prodotto e lotto e setta i dati per le pagine
  async function fetchLots() {
    try {
      let lots = await getLots(prod, lot)
      // Se il lotto esiste, allora popolo i campi e li mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      if (Object.entries(lots).length > 0) {
        // const expirationDate = lots.expirationDate
        lots = await populateLotData(lots)
        // dispatch({
        //   type: 'SET_EXPIRATION_DATE',
        //   payload: expirationDate
        // })
        dispatch({
          type: 'SET_LOT',
          payload: lots
        })
        dispatch({
          type: 'SET_LOT_LOADED',
          payload: true
        })

        dispatch({
          type: 'SET_INFORMATIONLOADED',
          payload: true
        })
      } else {
        dispatch({
          type: 'SET_LOT_NOT_FOUND',
          payload: true
        })
      }
    } catch (e) {
      dispatch({
        type: 'SET_LOT_NOT_FOUND',
        payload: true
      })
    }
  }

  // funzione che fa il fetch di prodotto e lotto e setta i dati per le pagine
  async function fetchLightLots() {
    try {
      let lots = await getLightLots(prod, lot)
      // Se il lotto esiste, allora popolo i campi e li mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      if (Object.entries(lots).length > 0) {
        // const expirationDate = lots.expirationDate
        lots = await populateLotData(lots)
        // dispatch({
        //   type: 'SET_EXPIRATION_DATE',
        //   payload: expirationDate
        // })
        dispatch({
          type: 'SET_LIGHT_LOT',
          payload: lots
        })
        dispatch({
          type: 'SET_LIGHT_LOT_LOADED',
          payload: true
        })

        dispatch({
          type: 'SET_INFORMATIONLOADED',
          payload: true
        })
      } else {
        dispatch({
          type: 'SET_LIGHT_LOT_NOT_FOUND',
          payload: true
        })
      }
    } catch (e) {
      dispatch({
        type: 'SET_LIGHT_LOT_NOT_FOUND',
        payload: true
      })
    }
  }

  // Custom hook che fa il fetch dei dati in base ai parametri ricevuti nell'url
  const useFetchData = () => {
    useEffect(() => {
      fetchProducts()
      fetchDiscoverResource({ gtin: prod })
      if (!lot) {
        dispatch({
          type: 'SET_MENU_SECTION',
          payload: 4
        })
      } else {
        fetchLots()
        fetchLightLots()
        dispatch({
          type: 'SET_MENU_SECTION',
          payload: 5
        })
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        dispatch({
          type: 'SET_LOT_NUMBER',
          payload: lot
        })
      }
    }, [])
  }

  // chiamata all'hook che fa il fetch dei dati
  useFetchData()

  useEffect(() => {}, [])
  // definisco stili e media query
  // const classes = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')

  const section0Ref = useRef(null) //PresentationPage
  //const section1Ref = useRef(null) //CharacteristicsPage
  const section2Ref = useRef(null) // CompanyInfo & CompanyInfoMobile
  //const section3Ref = useRef(null) //CertificationsTable
  const section4Ref = useRef(null) //ProductTab
  //const section5Ref = useRef(null)
  const section6Ref = useRef(null) //Disposal

  const sectionRefs = {
    section0Ref, //per gestire lo scroll in testa quando si clicca su product
    //section1Ref,
    section2Ref,
    //section3Ref,
    section4Ref,
    //section5Ref,
    section6Ref
  }

  const scrollToSection = (ref, offset = 150) => {
    if (ref.current) {
      // Calcola la posizione relativa
      const elementPosition = ref.current.getBoundingClientRect().top
      const scrollPosition = window.scrollY + elementPosition - offset

      // Effettua lo scroll con offset
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      })
    }
  }
  const handleScroll = () => {
    let activeSection = null
    // Ottieni le sezioni con la loro posizione attuale
    const sectionsWithPosition = Object.entries(sectionRefs)
      .map(([key, ref]) => {
        if (ref.current) {
          const { top, bottom } = ref.current.getBoundingClientRect()
          return { key, top, bottom }
        }
        return null
      })
      .filter(Boolean)
    // Trova la sezione visibile (usando la metà dello schermo)
    const screenHeight = window.innerHeight
    const threshold = screenHeight / 2
    const visibleSection = sectionsWithPosition.find(
      ({ top, bottom }) => top <= threshold && bottom >= threshold
    )
    if (visibleSection) {
      const sectionNumber = parseInt(visibleSection.key.match(/\d+/)[0], 10) // Estrai il numero dal nome della variabile
      activeSection = sectionNumber
    }
    // Aggiorna lo stato solo se necessario
    if (activeSection !== null) {
      dispatch({
        type: 'SET_MENU_SECTION',
        payload: activeSection
      })
      dispatch({
        type: 'SET_CLICK_MENU_SECTION',
        payload: false
      })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (
      sectionActive &&
      sectionRefs[`section${sectionActive}Ref`] &&
      clickedByMenu
    ) {
      const offset = sectionActive === 4 ? 500 : isSmall ? 140 : 150
      // if (sectionActive === 4) {
      //   scrollToSection(sectionRefs[`section${0}Ref`], offset)
      // } else {
      scrollToSection(sectionRefs[`section${sectionActive}Ref`], offset)
      // }
    }
  }, [sectionActive])

  // NOTE: quando non viene trovato il prodotto.
  if (!informationFound) {
    return <NotFound />
  } else {
    if (!informationLoaded) {
      return <LogoLoader size='large'></LogoLoader>
    } else {
      return (
        <SkeletonTheme color='#ddebdd' highlightColor='#d3e3d3'>
          {isSmall ? <SelectLanguage /> : null}

          <div>
            {/* dialog track documents */}
            <DocumentsDialog />
            {/* dialog track description */}
            <DescriptionDialog />
            {/* dialog certifications mobile */}
            <CertDialog />
            {/* content */}
            <div
              style={{
                minHeight: '98vh',
                backgroundColor: isSmall ? '#fafafa' : ''
              }}
            >
              {hideOrShowProperty(discover, 'backgroundImage', 'product') &&
              product &&
              product.backgroundImage &&
              product.backgroundImage.cropped !== '' &&
              !product.backgroundImage.cropped.includes(
                '339d96f7-8945-4eb9-b088-97c0d4243653.png'
              ) &&
              !product.metadata?.isProductBackgroundImageFrontendHide ? ( // uuid del background image default
                <Parallax
                  image={
                    product.backgroundImage.cropped !== ''
                      ? product.backgroundImage.cropped
                      : '/image-not-found.png'
                  }
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    overflow: 'hidden',
                    height: isSmall ? '20vh' : '35vh',
                    display:
                      isSmall &&
                      sectionActive !== 1 &&
                      sectionActive !== 2 &&
                      sectionActive !== 3 &&
                      sectionActive !== 4 &&
                      sectionActive !== 5 &&
                      sectionActive !== 6
                        ? 'none'
                        : ''
                  }}
                ></Parallax>
              ) : (
                <Box id='skeleton-background'>
                  {/* <Skeleton
                    duration={0.7}
                    height={isSmall ? '30vh' : '60vh'}
                    width='100%'
                  /> */}
                  <Parallax
                    image={''}
                    style={{
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      overflow: 'hidden',
                      height: isSmall ? '12vh' : '20vh',
                      display:
                        isSmall &&
                        sectionActive !== 1 &&
                        sectionActive !== 2 &&
                        sectionActive !== 3 &&
                        sectionActive !== 4 &&
                        sectionActive !== 5 &&
                        sectionActive !== 6
                          ? 'none'
                          : ''
                    }}
                  ></Parallax>
                </Box>
              )}

              <div
                style={
                  isSmall
                    ? presentationStyle.cardContainerMobile
                    : presentationStyle.cardContainer
                }
              >
                <Card
                  style={Object.assign(
                    {},
                    presentationStyle.main,
                    presentationStyle.mainRaised,
                    isSmall
                      ? {
                          padding: 10,
                          marginLeft: '0px',
                          marginRight: '0px',
                          borderRadius: '0px',
                          boxShadow: sectionActive !== 1 ? 'none' : '',
                          width: '95%'
                        }
                      : {
                          padding: 50,
                          width: '80%'
                        }
                  )}
                >
                  {/* <div ref={section0Ref}> */}
                  <div>
                    {isSmall ? (
                      <PresentationPageMobile
                        product={product}
                        company={company}
                        sectionActive={sectionActive}
                      />
                    ) : (
                      <PresentationPageDesktop product={product} />
                    )}
                  </div>
                  {/* nel caso dello small è già messo dentro al PresentationPageMobile, per dare un impilazione diversa */}

                  {/* <div ref={section4Ref}> */}
                  <div ref={section4Ref}>
                    <ProductTab />
                  </div>
                  {product?.waste?.length > 0 && (
                    <div ref={section6Ref}>
                      <Disposal />
                    </div>
                  )}
                  {!product?.metadata?.isCompanyFrontendHide && (
                    <>
                      {isSmall ? (
                        <div ref={section2Ref}>
                          <CompanyInfoMobile />
                        </div>
                      ) : (
                        <div ref={section2Ref}>
                          <CompanyInfo />
                        </div>
                      )}
                    </>
                  )}
                  {/* {isSmall ? null : (
                    <div ref={section1Ref}>
                      <CharacteristicsPage mobile={false} />
                    </div>
                  )} */}
                  {/* <div ref={section3Ref}>
                    <CertificationsTable />
                  </div> */}
                  {!isSmall && <Footer showLink={true} />}
                </Card>
              </div>
            </div>
          </div>
          {!state.product?.metadata?.isCompanyFrontendHide && isSmall && (
            <CompanyLogo />
          )}
          {isSmall && <Footer showLink={true} />}
        </SkeletonTheme>
      )
    }
  }
}
